<!-- talents_details -->
<template>
  <div class="talents_details">
    <header>
      <div class="left">
        <Button size="small" type="warning" @click="$router.back()">
          <Icon type="ios-arrow-back" />
          返回
        </Button>
        <Divider type="vertical" />
        <p style="font-size:18px">{{this.$route.meta.title}}</p>
      </div>

      <div class="right" v-if="!readStatus">
        <Button type="warning" @click="check_info">查看完整信息</Button>
      </div>

      <div class="right" v-else>
        <div @click="collect">
          <img v-if="user_details.collectStatus" src="@/assets/images/collected.png" alt="">
          <img v-else src="@/assets/images/collect.png" alt="">
          <p>收藏</p>
        </div>

        <Button type="warning" @click="download">下载简历</Button>
      </div>
    </header>

    <div style="position: relative;width : 1000px;
    margin: 0 auto;" ref="imageWrapper">
      <Spin size="large" fix v-if="spinShow"></Spin>
      <!-- 基本信息 -->
      <section>
        <Row>
          <Col span="19">
          <div class="title">
            <div></div>
            <p>基本信息</p>
          </div>
          <p class="name" style="margin-bottom:10px;">姓名:{{readStatus?user_details.name:user_details.name[0]}}{{readStatus?'':user_details.sex==1?'先生':'小姐'}}</p>
          <Row style="color:#000">
            <Col span="8">
            <p>性别：{{user_details.sex==1?'男':'女'}}</p>
            </Col>
            <Col span="8">
            <p>年龄：{{jsGetAge(user_details.birthDate)}}岁</p>
            </Col>
          </Row>

          <Row style="margin:6px 0;color:#000">
            <!-- <Col span="8">
            <p>工作经验：{{working_age(user_details.workedDate)}}年</p>
            </Col> -->
            <Col span="8">
            <p>学历: {{user_details.education}}</p>
            </Col>
            <Col span="8">
            <p>联系电话： <span style="color:#FF8300">{{readStatus?user_details.phone:user_details.phone.replace(user_details.phone.substring(3,7), "****")}}</span> </p>
            </Col>
          </Row>

          <Row style="color:#000">
            <Col span="8">
            <p>
              户籍：
              <span>{{user_details.region[0]}}-</span>
              <span>{{user_details.region[1]}}-</span>
              <span>{{user_details.region[2]}}</span>
            </p>
            </Col>
            <!-- <Col span="12">
            <p v-if="user_details.workedDate.length">
              参加工作时间：
              <span>{{user_details.workedDate[0]}}-</span>
              <span>{{user_details.workedDate[1]}}-</span>
              <span>{{user_details.workedDate[2]}}</span>
            </p>
            </Col> -->
            <Col span="14" v-if="user_details.workArea">
              灵活就业目标区域：
              <span>{{user_details.workArea[0]}}-</span>
              <span>{{user_details.workArea[1]}}-</span>
              <span>{{user_details.workArea[2]}}</span>
            </p>
            </Col>
          </Row>

          <!-- <Row style="margin:6px 0;color:#000">
            
          </Row> -->

          <Row style="margin:6px 0" :class="readStatus?'':'dim'">
            <Col span="24">
            <p>上班出发地：{{user_details.personalAddress}}</p>
            </Col>
          </Row>

          <Row>
            <Col span="24" :class="readStatus?'':'dim'">
            <p>自我简介：{{user_details.profiles}}</p>
            </Col>
          </Row>

          </Col>

          <Col span="5" style="text-align:center;padding-top:20px">
          <img style="width:211px;margin-top:27px" src="@/assets/images/B_user/title.png" alt="">
          </Col>
        </Row>
      </section>

      <div :class="readStatus?'':'dim'">

        <!-- 全职求职意向 -->
        <section>
          <Row>
            <Col span="19">
            <div class="title">
              <div></div>
              <p>灵活就业意向</p>
            </div>
            <ul>
              <li>
                <p style="flex:1">求职意向：{{ user_details.fullTimeIntention.category_name.toString() }}</p>
              </li>
              <li>
                <p>期望日酬：{{ user_details.fullTimeIntention.daily_income }}</p>
                <p>期望月酬：{{ user_details.fullTimeIntention.month_income }}</p>
              </li>
              <li>
                <p>每周可灵活工作时间：{{ user_details.fullTimeIntention.work_time.toString() }}</p>
                <p>每天可灵活工作时段：{{ user_details.fullTimeIntention.work_hour }}</p>
              </li>
              <li>
                <p style="flex:1">上下班路程（步行或骑行）：{{ user_details.fullTimeIntention.work_way }}</p>

              </li>
            </ul>
            </Col>
            <Col span="5">
            </Col>
          </Row>
        </section>

        <!-- 工作经历 -->
        <section>
          <Row>
            <Col span="19">
            <div class="title">
              <div></div>
              <p>工作经历</p>
            </div>
            <div v-if="user_details.workRecords.length">
              <div class="content" v-for="(item,index) in user_details.workRecords" :key="item._id">
                <div class="content_o">
                  {{index+1}}
                </div>
                <div>
                  <p style="margin-bottom:8px">岗位名称：</p>
                  <p>工作内容：</p>
                </div>
                <div style="flex:1">
                  <p style="color:#909399;margin-bottom:8px">{{ item.work_name }}</p>
                  <p style="color:#909399;">{{ item.work_description }}</p>
                </div>

              </div>

            </div>

            <p v-else>未完善</p>

            </Col>
            <Col span="3">
            </Col>
          </Row>
        </section>

        <!-- 技能证书 -->
        <section>
          <Row>
            <Col span="19">
            <div class="title">
              <div></div>
              <p>职业技能</p>
            </div>
            <div v-if="user_details.eduRecords.length">
              <div class="content" v-for="(item,index) in user_details.eduRecords" :key="item._id">
                <div class="content_o">
                  {{index+1}}
                </div>
                <div style="">
                  <p style="margin-bottom:8px">技能名称：</p>
                  <p>技能描述：</p>
                </div>
                <div style="flex:1">
                  <p style="color:#909399;margin-bottom:8px">{{ item.name }}</p>
                  <p style="color:#909399;">{{ item.specialities }}</p>
                </div>

              </div>

            </div>

            <p v-else>未完善</p>
            </Col>
            <Col span="3">
            </Col>
          </Row>
          <p style="text-align:center;margin:20px 0 0">此简历由宝妈灵活就业网提供</p>
        </section>

      </div>

    </div>
  </div>
</template>

<script>

import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
export default {
  data() {
    return {
      spinShow: false,
      //true:已查看  false:未查看作模糊处理
      readStatus: false,
      user_details: {
        name: '',
        birthDate: "",
        workedDate: "",
        native: [],
        address: [],
        fullTimeIntention: {
          workplace: [],
          category_name:[],
          work_time:[]
        },
        region:[],
        temporaryIntention: {
          area: [],
          salary: ""
        },
        workRecords: [],
        eduRecords: [],
        skillsCertificate: [],
        phone: ""
      }
    }
  },
  computed: {
    //参加工作时间获取工作经验年龄
    // working_age() {
    //   return (data) => {
    //     var workedDate = data[0] + data[1] + data[2] + data[3];
    //     var y = new Date().getFullYear()
    //     return y - workedDate
    //   }
    // }
  },
  methods: {
    //收藏or取消收藏
    collect() {
      let status = this.user_details.collectStatus ? 0 : 150
      this.app("enterpriseOperators", "recommendCollectStatus", {
        enterpriseId: localStorage.getItem("firm_enterpriseId"),
        userId: this.$route.query.id,
        status: status
      }).then(res => {
        this.$Modal.warning({
          title: res.message,
          closable: true
        })
        if (res.code == 200) {
          this.user_details.collectStatus = status ? true : false;
        }
      })

    },
    //下载简历
    download() {
      /** 生成图片*/
      html2canvas(this.$refs.imageWrapper).then(canvas => {
        let dataURL = canvas.toDataURL("image/png");
        if (dataURL !== "") {
          var pdf = new JsPDF('', 'pt', 'a4');
          pdf.addImage(dataURL, 'JPEG', 0, 0, 595.28, 592.28 / canvas.width * canvas.height);
          pdf.save(this.user_details.name + '简历详情');

          // let alink = document.createElement("a");
          // alink.href = dataURL;
          // alink.download = "downloadName.png";
          // alink.click();
        }
      });
    },
    //查看完整信息
    check_info() {
      this.app("enterpriseOperators", "readRecommendationLetter", {
        enterpriseId: localStorage.getItem("firm_enterpriseId"),
        userId: this.$route.query.id
      }).then(res => {
        if (res.data) {
          this.readStatus = res.data
        } else {
          this.$Modal.warning({
            title: res.message
          })
        }
      })
    },
    //封装出生年月转换年龄
    jsGetAge(strBirthday) {
      var returnAge;
      var strBirthdayArr = strBirthday.split("-");

      var birthYear = strBirthdayArr[0];
      var birthMonth = strBirthdayArr[1];
      var birthDay = strBirthdayArr[2];

      var d = new Date();
      var nowYear = d.getFullYear();
      var nowMonth = d.getMonth() + 1;
      var nowDay = d.getDate();
      if (nowYear == birthYear) {
        returnAge = 0;//同年 则为0岁
      }
      else {
        var ageDiff = nowYear - birthYear; //年之差

        if (ageDiff > 0) {
          if (nowMonth == birthMonth) {
            var dayDiff = nowDay - birthDay;//日之差
            if (dayDiff < 0) {
              returnAge = ageDiff - 1;
            }
            else {
              returnAge = ageDiff;
            }
          }
          else {
            var monthDiff = nowMonth - birthMonth;//月之差
            if (monthDiff < 0) {
              returnAge = ageDiff - 1;
            }
            else {
              returnAge = ageDiff;
            }
          }
        }
        else {
          returnAge = '';//返回-1 表示出生日期输入错误 晚于今天
        }
      }
      return returnAge;//返回周岁年龄
    },
    //封装获取数据
    get_data() {
      this.spinShow = true
      this.app("enterpriseOperators", "readStatusByUserId", {
        enterpriseId: localStorage.getItem("firm_enterpriseId"),
        userId: this.$route.query.id,
      }).then(res => {
        console.log(res);
        this.readStatus = res.readStatus
        this.user_details = res.result[0]
        //定义属性
        if (this.user_details.collectStatus) {
        } else {
          this.$set(this.user_details, 'collectStatus', false)
        }



        if (typeof (this.user_details.fullTimeIntention.jobIntention) == 'object') {
          this.user_details.fullTimeIntention.jobIntention = this.user_details.fullTimeIntention.jobIntention.join(',')
        }


        this.spinShow = false
      })
    },
  },
  created() {
    this.$nextTick(() => {
      document.getElementsByClassName("ivu-layout")[1].scrollTop = 0
    });

    this.get_data()
    this.app("enterpriseOperators", "getEnterprise", {
      enterpriseId: localStorage.getItem("firm_enterpriseId"),
    }).then(res => {
    })
  }

}
</script>

<style lang="less" scoped>
@import "./talents_details.less";
</style>
